<script lang="ts" setup>
import { useToast } from 'vue-toastification'
import * as yup from 'yup'

const toast = useToast()
const { emailValidation } = useValidations()
const { $t } = useI18n()
async function onSubmit (values: any, { resetForm }: any) {
  const { error } = await useFetch('/api/fubex-components/newsletter/subscribe', {
    method: 'POST',
    body: {
      email: values.email,
    },
  })

  if (error.value) {
    toast.error($t('components.newsletter.errorMessage'))
    return
  }

  resetForm()
  toast.success($t('components.newsletter.successMessage'))
}

const validationSchema = yup.object({
  email: emailValidation,
})
</script>

<template>
  <VForm
    v-slot="{ meta, isSubmitting }"
    :validation-schema="validationSchema"
    relative fubex-rounded-xl
    bg-primary-500 dark:bg-neutral-200
    flex flex-col gap-8px p-16px pt-98px mt-130px
    lg="block p-40px pr-372px mt-174px"
    @submit="onSubmit"
  >
    <h3 heading3 widget-newsletter-text-color font-500>
      {{ $t('components.newsletter.heading') }}
    </h3>
    <p paragraph-md widget-newsletter-text-color my-8px>
      {{ $t('components.newsletter.description') }}
    </p>
    <div flex gap-8px>
      <FInputVee
        name="email"
        cypress-prefix="newsletter"
        type="text" :placeholder="String($t('components.newsletter.emailPlaceholder'))"
        lg="w-420px"
      />
      <FButton name="news-remove" :disabled="!meta.valid" variant="dark" size="lg" max-h-50px :loading="isSubmitting" type="submit">
        {{ $t('components.newsletter.subscribeButton') }}
      </FButton>
    </div>
    <p mt-4px widget-newsletter-text-color paragraph-sm>
      {{ $t('components.newsletter.consent') }}
    </p>
    <NuxtImg
      absolute
      class="right-[calc(50%-115px)]" w-216px top--125px
      lg="right-32px top--113px w-340px h-342px"
      :src="`${'/img/t-wallet.webp'}`" alt="Bitbeli Newsletter"
    />
  </VForm>
</template>
